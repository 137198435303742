import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function AtlassianGuide() {
  return (
    <Layout
      keywords="Atlassian installation guide, Confluence and Jira documentation"
      title="Set up IrisAgent AI on your Atlassian Jira and Confluence account"
      description="Documentation on how to integrate IrisAgent with your Atlassian Jira and Confluence accounts to supercharge your support operations with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/atlassian-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Atlassian Installation Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Atlassian Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Installation Guide for Atlassian Jira and Confluence</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

IrisAgent provides AI-powered correlations between Jira issues and support tickets using the Atlassian Jira integration. It enables cross-functional teams to better prioritize product issues based on support and business impact.

IrisAgent provides AI-powered answers to support tickets from internal knowledge base in Confluence using the Atlassian Confluence integration.
                    <br/>
                    <br/>
                    <h2>
                    Connect with Atlassian
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Login to the <a href="https://web.irisagent.com" target="_blank">IrisAgent dashboard</a>. This step assumes that you have already connected IrisAgent to your ticketing provider.<br/>
                    <img
                src="/img/docs-login.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Manage Integrations</b> option on the bottom left menu. <br/>
                    </li>
                    <li>
                     If you are using Atlassian cloud instance, click on <b>Connect with Atlassian/Jira Cloud</b>. Else, if you are using Atlassian on-prem instance, click on <b>Connect with Atlassian/Jira Server</b>. Give the OAuth permissions and click on Allow. This will automatically connect IrisAgent with Jira and Confluence.
                     <br/><img
                src="/img/atlassian-docs.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />

                    <ul>
                    <li>
                    [Atlassian Cloud] If you are connecting with Atlassian Cloud, a follow-on page will show up that asks for the Atlassian cloud token, email address, and base URL. Here are <a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target="_-_blank">the steps to create the token</a>.
                    <br/><img
                src="/img/jira-cloud-token.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    [Atlassian Server] If you are connecting with Atlassian Server, a follow-on page will show up that asks for the Atlassian server token and base URL. Here are the <a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target="_-_blank">steps to create the token</a>.
                    <br/><img
                src="/img/server-token.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ul>
                    </li>
                    </ol>
                    <br/>


The above steps will complete the integration of IrisAgent with Atlassian. Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> once these steps are completed, and our team will start setting up the machine learning models for your account.

                <br/><br/><br/>
                    <h2>
                    Selecting certain Jira Projects or Confluence Spaces
                    </h2><br/>

                    <ol>
                        <li> When creating the token in the step above, ensure you only give permissions to the relevant projects/spaces.
                        </li><br/>
                        <li> After you connect with your Atlassian account, the Atlassian tile on the "Manage Integrations" page should as connected. Click on the Settings gear icon. This is show a side panel where you can select/deselect which projects/spaces you want IrisAgent to have access to.
                        </li>

                    </ol>
                <br/><br/>
                    <h2>
                    Details on IrisAgent's Atlassian Connection
                    </h2>
                    IrisAgent only ingests data from Atlassian from the last 3 months (can be configured). It make POST and GET API calls to fetch data while ensuring that the Atlassian's rate limits are not exceeded. The frequency of API call can be configured based on customer's preference.
                    <br/><br/>
                    <h3>
                    Permissions of the Atlassian Token
                    </h3>
                    The Atlassian token generated by a user has the same access level as that user’s permissions within jira. It is recommended that a service user be created and that user’s token be entered in the IrisAgent dashboard, so that permissions can be easily granted/revoked as and when new functionality is introduced.

                    <br/><br/>
                    <h3>
                    OAuth permissions needed for Jira Software
                    </h3>
                    IrisAgent uses this integration to correlate Jira Software bugs with support cases automatically, prioritize open bugs using business and support impact and provides visibility to customer support, engineering, and product management teams.

                    The token needs to have the following permissions in Jira:
                    <br/><br/>
                    <ol>
                    <li>
                    Read for all Jira issues (and comments). If you'd like to sign up for the <b>optional</b> trigger features to write comments and tags on Jira, please include write permissions too.
                    </li>
                    <li>
                    Read all Jira projects
                    </li>
                    <li>
                    Access to the /issue/createmeta endpoint to discover the project and issue types while creating a new Jira issue from the IrisAgent app. This is another <b>optional</b> feature to be able to create Jira issues from your ticketing system.
                    </li>
                    </ol>


                    <br/><br/>
                    <h3>
                    OAuth permissions needed for Confluence
                    </h3>
                    IrisAgent uses this integration to automatically suggest articles and resolutions from Confluence that match a new support case and assist both support reps and customers in finding faster resolutions.

                    The token needs to have the following permissions in Confluence:
                    <br/><br/>
                    <ol>
                    <li>
                    Read all blogs
                    </li>
                    <li>
                    Read all pages
                    </li>
                    </ol>
                    <br/>
                    <h3>
                    Granting Access only to either Jira/Confluence
                    </h3>
                    <ol>
                    <li>
                    Create a service user who has access only to the feature you want to use. E.g. : A service user with access restricted only to Confluence.
                    </li>
                    <li>
                    If the service user has access to one of Jira/conlfluence, please ensure that all permissions required within that section are met.
                    </li>
                    <li>
                    Generate a token for this service user and follow the same connection procedure by visiting the “Manage Integrations” section as mentioned above.s
                    </li>
                    </ol>
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
